<template>
  <div>
    <div class="loading flex flex-row items-center justify-center fixed left-0 top-20 md:top-40 lg:top-60 w-full bottom-0 z-100 bg-black transition-all duration-500" :class="[{'invisible opacity-0': collections.length}]">
      <loading-spinner />
    </div>
    <div class="page min-h-screen">
      <div class="collection">
        <div class="outer-container">
          <div class="inner-container">
            <div class="breadcrumbs mt-12 text-grey-400 text-12 uppercase">
              <router-link to="/discover" class="hover:text-white transition-colors">Discover</router-link><span class="divider inline-block px-4">&gt;</span><span class="text-white">Collections</span>
            </div>
          </div>
        </div>
        <curated-collections class="mt-16 md:mt-0" :show-all="false" :show-spotlight="false" :collections="collections" :modular-content="collectionsContent" v-if="collections.length" intro="" heading="Curated collections —" />
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import Kontent from "@/kontent";
import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'
import LoadingSpinner from "@/components/LoadingSpinner";
import CuratedCollections from "@/components/CuratedCollections";
Vue.use(VueMasonry);
export default {
  name: 'DiscoverCollection',
  metaInfo () {
    if (!this.page) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {property: 'og:description', content: this.page.elements.page__page_description.value},
      {name: 'title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
      {name: 'description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
      {name: 'twitter:description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value}
    ]

    // if there's an image
    if (this.page.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.page.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
    }

    return {
      title: this.page.elements.page__page_title.value.length ? this.page.elements.page__page_title.value : 'CONNECT',
      meta: meta
    }
  },
  components: {CuratedCollections, LoadingSpinner},
  props: {},
  data () {
    return {
      collections: null,
      collectionsContent: null,
      page: null
    }
  },
  computed: {

  },
  mounted () {
    Analytics.trackPage('Collections')

    if (this.$auth.isAuthenticated) {
      this.$store.dispatch("getFavouriteProducts")
      this.$store.dispatch("getFavouriteWineries")
    }

    Kontent.getAllCollections().then((res) => {
      this.collections = res.data.items
      this.collectionsContent = res.data.modular_content

      setTimeout(function(){window.prerenderReady = true}, 1000)
    })

    Kontent.getItemByType('collections_page').then((res) => {
      this.page = res.data.items[0]

      setTimeout(function(){window.prerenderReady = true}, 1000)
    })
  }
}
</script>

<style scoped lang="scss">
.section-heading {
  font-weight: 600;
  font-size: 24px;

  @media(min-width: 768px)
  {
    font-size: 34px;
  }
}
</style>
